html {
  scroll-behavior: smooth;
}

.text-gradient {
  background: linear-gradient(90deg, #F7F7F7 40%, #868686 60%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bg-full {
  background-image: url(./assets/bg-full.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.border-gradient {
  border-radius: 10px;
  border: 2px solid;
  border-image-source: linear-gradient(90deg, #232526 0%, #414345 100%);
  background:
    linear-gradient(to right, #040612, #040612),
    linear-gradient(90deg, #232526 0%, #414345 100%);
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
}



/* ---Sidebar css */

.sidebar {
  width: 250px;
  min-height: 100vh;
  box-shadow: 0px 4px 8px rgb(0 0 0 / 8%);
  background: #050610;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 0.5s;
  z-index: 50;
}

.sidebar.active {
  left: 0;
}

.sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
}

.sidebar-overlay.active {
  opacity: 1;
  visibility: visible;
}

.sd-body {
  padding: 10px;
  max-height: calc(100vh - 67px);
  overflow-x: hidden;
}

.sd-body ul {
  display: inline-block;
  width: 100%;
  margin-bottom: 0;
  padding: 0;
}

.sd-body ul li {
  list-style: none;
  margin-bottom: 10px;
  width: 100%;
}


/* star animation */

.star-field {
  position: fixed;
  width: 98vw;
  height: 100vh;
  overflow: hidden;
}

.star {
  position: absolute;
  width: 2.6px;
  height: 2.6px;
  background: rgb(235, 224, 224);
  opacity: 0;
  border-radius: 50%;
  animation: twinkle 2s infinite ease-in-out;
}

@keyframes twinkle {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}